//Badge Mixins

//Badge vriations
@mixin badge-variations($color) {
	border: 1px solid $color;
	color: $white;
}
// Badge outlined variations
@mixin badge-outline-variations($color) {
	color: $color;
	border: 1px solid $color;
}
@mixin badge-inverse-variant($color, $color-hover: $white) {
    background-color: rgba($color, 0.2);
    background-image: none;
    border-color: rgba($color, 0);
    &:not(.badge-inverse-light) {
      color: $color;      
    }
    @include hover {
      color: $color-hover;
      background-color: $color;
      border-color: $color;
    }
  
    &.focus,
    &:focus {
      box-shadow: 0 0 0 3px rgba($color, .5);
    }
}