/* Badges */

.badge {
  border-radius: 4px;
  font-size: 11px;
  font-weight: medium;
  line-height: 1;
  padding: 5px 7px;
  font-family: $type1;

  &.badge-pill {
    border-radius: 10rem;
  }
}


/*Badge variations*/
@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variations($value);
  }
}

/*Badge outlined variations*/
@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variations($value);
  }
}

@each $color, $value in $theme-colors {
  .badge-inverse-#{$color} {
    @include badge-inverse-variant($value);
  }
  .badge-#{$color}:not(.badge-light) {
    color: $white;
    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
    &:focus,
    &:active {
      background: $value;
      border-color: $value;
    }
  }
  .badge-outline-#{$color} {
    &:hover,
    &:focus,
    &:active {
      background: theme-gradient-color($color);
      color: $white;
    }
  }
}