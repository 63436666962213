////////// COLOR SYSTEM //////////

$blue: #5e50f9;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #e91e63;
$red: #f96868;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray: #2e383e;
$dodger-blue: #3498db;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: #434a54,
  gray-light: #aab2bd,
  gray-lighter: #e8eff4,
  gray-lightest: #e6e9ed,
  gray-dark: #0f1531,
  black: #000000,
);

$theme-colors: (
  primary: #3f50f6,
  secondary: #d8d8d8,
  success: #00cccd,
  info: #198ae3,
  warning: #ffab2d,
  danger: #ff3ca6,
  light: #f8f9fa,
  dark: #3e4b5b,
);

////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #f2f2f2;
$footer-bg: $content-bg;
$footer-color: color(dark);
$border-color: #ebedf2;
////////// COLOR VARIABLES //////////

////////// SOCIAL COLORS //////////

$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500,
);

////////// SOCIAL COLORS //////////

////////// FONTS//

$type1: "Rubik", sans-serif;

$default-font-size: 0.875rem; // 14px as base font size

$text-muted: #6c7293;
$body-color: #343a40;

////////// FONT VARIABLES //////////

////////// SIDEBAR ////////
$sidebar-width-lg: 260px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: $white;
$sidebar-light-menu-color: #3e4b5b;
$sidebar-light-submenu-color: #3e4b5b;
$sidebar-light-menu-active-bg: $white;
$sidebar-light-menu-active-color: theme-color(primary);
$sidebar-light-menu-hover-bg: darken($sidebar-light-bg, 1%);
$sidebar-light-menu-hover-color: darken($sidebar-light-menu-color, 10%);
$sidebar-light-submenu-hover-color: darken($sidebar-light-submenu-color, 20%);
$sidebar-light-menu-icon-color: #423a8e;
$sidebar-light-menu-arrow-color: #9e9da0;
$sidebar-light-menu-border-color: #c0bfcf;

$sidebar-dark-bg: #18151e;
$sidebar-dark-menu-color: $white;
$sidebar-dark-menu-active-bg: #282037;
$sidebar-dark-menu-active-color: darken($white, 30%);
$sidebar-dark-menu-hover-bg: lighten($sidebar-dark-bg, 10%);
$sidebar-dark-menu-hover-color: color(white);
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: darken($sidebar-dark-submenu-color, 30%);

$sidebar-dark-menu-icon-color: #bba8bff5;
$sidebar-dark-menu-arrow-color: #9e9da0;

$sidebar-menu-font-size: 15px;
$sidebar-menu-padding-y: 12px;
$sidebar-menu-padding-x: 2.25rem;

$sidebar-submenu-font-size: 0.8125rem;
$sidebar-submenu-item-padding: 0.75rem 2rem 0.75rem 2rem;

$sidebar-icon-font-size: 20px;
$sidebar-arrow-font-size: 0.625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0.75rem 1.625rem 0.75rem 1.188rem;

$sidebar-mini-menu-padding: 0.8125rem 1rem 0.8125rem 1rem;

$sidebar-icon-only-menu-padding: 0.5rem 1.625rem 0.5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$rtl-sidebar-submenu-padding: 0 1.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-menu-hover-bg;
$icon-only-menu-bg-dark: $sidebar-dark-menu-hover-bg;

///////// SIDEBAR ////////

///////// CARD ////////
$card-bg: #fff;
$card-description-color: #76838f;
$card-title-color: $body-color;
$card-shadow-color: 0 2px 10px 0 rgba(197, 191, 191, 0.5);
$list-card-bg: $white;
///////// CARD ////////

//////// TABLES ////////
$table-accent-bg: $content-bg;
$table-hover-bg: $content-bg;
$table-cell-padding: 0.9375rem;
$table-border-color: $border-color;
$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);
$thead-bg: #f4f4f4;
$table-bordertop-color: #ebedf2;
//////// TABLES ////////

////////// MODALS VARIABLES //////////
$modal-content-bg: $content-bg;
////////// MODALS VARIABLES //////////

///////// NAVBAR ////////
$navbar-bg: #000;
$navbar-height: 70px;
$navbar-menu-color: #8e94a9;
$navbar-font-size: 0.875rem;
$navbar-icon-font-size: 17px;
$navbar-item-bg: #3f3f40;
$navbar-item-light-bg: #e6e5f2;
$boxed-layout-bg: #e1d7ea;
$rtl-sidebar-submenu-padding: 10px;
$editor-color: $black;
$timeline-bg: $white;
$line-bg: theme-color(secondary);

///////// NAVBAR ////////

///////// BUTTONS ////////

$button-fixed-width: 150px;
$btn-padding-y: 3px;
$btn-padding-x: 18px;
$btn-line-height: 1.71;

$btn-padding-y-xs: 0.5rem;
$btn-padding-x-xs: 0.75rem;

$btn-padding-y-sm: 3px;
$btn-padding-x-sm: 17px;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;

$btn-font-size: 0.875rem;
$btn-font-size-xs: 0.625rem;
$btn-font-size-sm: 14px;
$btn-font-size-lg: 0.875rem;

$btn-border-radius: 0.1875rem;
$btn-border-radius-xs: 0.1875rem;
$btn-border-radius-sm: 0.1875rem;
$btn-border-radius-lg: 0.1875rem;

///////// BUTTONS ////////

////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size: 0.75rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.75rem;
$tooltip-border-radius: 0.375rem;

////////// TOOLTIP VARIABLES //////////

/////////  FORMS /////////

$input-bg: color(white);
$input-border-radius: 50px;
$input-placeholder-color: #c9c8c8;
$input-font-size: 0.8125rem;

$input-padding-y: 0.94rem;
$input-padding-x: 1.375rem;
$input-line-height: 1;

$input-padding-y-xs: 0.5rem;
$input-padding-x-xs: 0.75rem;

$input-padding-y-sm: 0.5rem;
$input-padding-x-sm: 0.81rem;

$input-padding-y-lg: 0.94rem;
$input-padding-x-lg: 1.94rem;

$input-height: 2.875rem;
$input-height-sm: 2.575rem;
$input-height-lg: 3.175rem;

///////// FORMS /////////

////////  DROPDOWNS ///////
$dropdown-border-color: $border-color;
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-header-color: $body-color;
$dropdown-menu-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
////////  DROPDOWNS ///////

////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$boxed-container-width: 1200px;
$border-property: 1px solid $border-color;
$card-spacing-y: 30px;
$card-padding-y: 20px;
$card-padding-x: 30px;
$card-border-radius: 3px;
$card-border-color: #e6e6e6;
$grid-gutter-width: 30px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
////////// OTHER VARIABLES //////////

////////// BREAD CRUMBS VARIABLES //////////

// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
$breadcrumb-bg: transparent;
$breadcrumb-border-color: $border-color;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: "/";

// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
$breadcrumb-custom-item-color: $black;
$breadcrumb-item-bg: #dbe3e6;
$survey-head-color: #a7afb7;
$datepicker-head-bg: #eef1f2;
$list-border-bg: #e8e8e8;
$accordian-head-color: $black;
$ion-slider-bg: #e1e4e9;
$light-color-icon-bg: #f2f2f2;
////////// BREAD CRUMBS VARIABLES //////////

////////// MODALS VARIABLES //////////
$modal-inner-padding: .9375rem;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, 0.5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;

$modal-header-border-color: $border-color;
$modal-content-border-color: $border-color;
$modal-footer-border-color: $border-color;

$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;

$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;

$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;

$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;

$modal-lg: 90%;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform 0.4s ease;

////////// MODALS VARIABLES //////////

/////////  TABS VARIABLES //////////
$nav-tabs-border-color: #ebedf2;
$nav-tabs-link-bg: #f6f8fa;
$nav-tabs-link-color: #000000;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color
  $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color
  $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg: #fcfcfd;
////////   TABS VARIABLES /////////

///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
///////// SETTINGS PANEL ////////

////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
$checkbox-bg: #7057d2;
$checkbox-border-color: #7057d2;
////////   CALENDAR  //////////////

////////   MISC  //////////////
$page-title-color: $body-color;
////////   MISC  //////////////
